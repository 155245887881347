import styled from 'styled-components';

export default styled.div`
	position: relative;
	margin: 0.6em 0;
	/* margin: 0.55em 0 0.15em 0.8em; */
	align-self: flex-start;

	// mobile settings
	@media (max-width: 768px) {
		margin: 0.55em 0 0.15em 0em;
	}
`;
