import React, { useState, useEffect, useRef, useContext } from 'react';
import styled from 'styled-components';
import { navigate, Link } from 'gatsby';
import Dropdown from './dropdown';
import CustomContext from '../../../custom-context';

const SubMenuStyled = styled.div`
	position: relative;
	margin: 0em 0.7em;
	font-size: var(--font-size-base);
	align-self: center;

	.openArrow {
		background: #f1f1f1;
		border-top-left-radius: 2px;
		bottom: -8px;
		height: 16px;
		left: 50%;
		position: absolute;
		-webkit-transform: translateX(calc(-50% - 10px)) rotate(45deg);
		transform: translateX(calc(-50% - 10px)) rotate(45deg);
		width: 16px;
	}

	.menuButton {
		align-items: center;
		border-radius: 4px;
		color: #fff;
		cursor: pointer;
		display: inline-flex;
		font-size: var(--font-size-base);
		padding: 10px 0px;
		position: relative;
		text-decoration: none;
	}

	.menuButton:hover {
		background-color: #ffffff0d;
		text-decoration: underline;
		color: var(--link_color);
	}

	.menuButton1 {
		background: transparent;
		border: 0;
		color: var(--secondary_2);
		font-family: inherit;
		font-size: inherit;
	}
	.menuButton2 {
		white-space: nowrap;
	}

	// mobile settings
	@media (max-width: 1199.5px) {
		margin-left: 0px;
		border-bottom: 1px solid #ccc !important;
		border-radius: 0px;
		align-self: auto;
		.menuButton {
			font-weight: 600;
			font-size: var(--font-size-base);
			/* padding: 10px 0px; */
		}
		.openArrow {
			display: none;
		}
		.accordianArrow {
			margin-left: 0.5em;
			background-color: #f5f5fa;
		}
		.accordianArrowClose {
			transform: rotate(180deg);
		}
	}
	// mobile settings
	@media (max-width: 768px) {
		border-bottom: 1px solid #ccc !important;
		border-radius: 0px;
		padding: 10px 0px;
		width: 100%;
	}
`;
export default function SubMenu(props) {
	const { menuItem } = props;
	const [dropdown, setDropdown] = useState(false);
	const { user, setUser, setToken, handleLogout } = useContext(CustomContext);
	//for detecting clicks outside of the dropdown
	let ref = useRef();

	// detect clicks outside of the dropdown
	useEffect(() => {
		const handler = (event) => {
			if (dropdown && ref.current && !ref.current.contains(event.target)) {
				setDropdown(false);
			}
		};
		document.addEventListener('mousedown', handler);
		document.addEventListener('touchstart', handler);
		return () => {
			// Cleanup the event listener
			document.removeEventListener('mousedown', handler);
			document.removeEventListener('touchstart', handler);
		};
	}, [dropdown]);

	return (
		<SubMenuStyled
			ref={ref}
			// className='menuButton'
		>
			{menuItem.title === 'Login' && user !== null && user !== undefined ? (
				<div
					className='loginButton'
					onClick={() => {
						handleLogout();
					}}
					onKeyDown={() => {
						handleLogout();
					}}
					role='button'
					tabIndex='0'
				>
					{' '}
					Logout
				</div>
			) : (
				<button
					aria-haspopup='menu'
					aria-expanded={dropdown ? 'true' : 'false'}
					onClick={() => setDropdown((prev) => !prev)}
					className={
						menuItem.title === 'Login'
							? 'loginButton'
							: 'menuButton menuButton1 menuButton2'
					}
				>
					{menuItem.title}
					{/* arrow icon svg */}
					<svg
						stroke='currentColor'
						fill='currentColor'
						stroke-width='0'
						viewBox='0 0 24 24'
						className={
							dropdown ? 'accordianArrow accordianArrowClose' : 'accordianArrow'
						}
						height='1em'
						width='1em'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							fill='none'
							// fill={menuItem.title === 'Login' ? '#353a40' : '#f5f5fa'}
							d='M0 0h24v24H0z'
						></path>
						<path
							fill={menuItem.title === 'Login' ? '#fff' : '#252525'}
							d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z'
						></path>
					</svg>
					{dropdown && <span className='openArrow'></span>}
				</button>
			)}
			{dropdown && (
				<Dropdown
					setDropdown={setDropdown}
					setExpanded={props.setExpanded}
					items={menuItem.items}
					dropdown={dropdown}
				/>
			)}
		</SubMenuStyled>
	);
}
