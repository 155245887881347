import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

export default function ImageComponent({
	imageItem,
	className,
	alt,
	objectFit,
}) {
	if (imageItem.localFile?.childImageSharp) {
		return (
			<GatsbyImage
				image={imageItem?.localFile?.childImageSharp?.gatsbyImageData}
				alt={
					alt
						? alt
						: imageItem !== null && imageItem.localFile.alternativeText
						? imageItem.localFile.alternativeText
						: 'Card Image'
				}
				className={className}
				objectFit={objectFit ? objectFit : ''}
			/>
		);
	} else {
		return (
			<img
				src={imageItem?.url}
				alt={
					alt
						? alt
						: imageItem !== null && imageItem.alternativeText
						? imageItem.alternativeText
						: 'Card Image'
				}
				className={className}
			></img>
		);
	}
}
