import { Link } from 'gatsby';
import React from 'react';

export default function LinkComponent({ to, children, ...other }) {
	const internal = /^\/(?!\/)/.test(to);
	if (internal) {
		return (
			<Link to={to} {...other}>
				{children}
			</Link>
		);
	} else {
		return (
			<a href={to} {...other}>
				{children}
			</a>
		);
	}
}
