import React from 'react';
import { Button } from 'react-bootstrap';
import { ChevronRight } from 'react-bootstrap-icons';

export default function buttonComponent(props) {
	const {
		analyticsTrackTitle,
		analyticsTitle,
		windowLocationHref,
		buttonName,
		iconVisible,
	} = props;
	return (
		<Button
			onClick={() =>
				window.analytics.track(analyticsTrackTitle, {
					title: analyticsTitle,
					action: 'Clicked',
					location: windowLocationHref,
				})
			}
		>
			{buttonName !== null ? buttonName : ''}{' '}
			{iconVisible ? <ChevronRight className='btn-icon' size={18} /> : ''}
		</Button>
	);
}
