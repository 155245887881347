import styled, { css } from 'styled-components';
import SearchResult from './searchResult';

const Popover = css`
	max-height: 80vh;
	overflow: scroll;
	-webkit-overflow-scrolling: touch;
	position: absolute;
	z-index: 1002;
	/* right: 0; */
	@media (max-width: 768px) {
		left: 0;
	}
	top: 100%;
	margin-top: 0.5em;
	width: 80vw;
	max-width: 30em;
	box-shadow: 0 0 5px 0;
	padding: 1em;
	border-radius: 2px;
	background: ${({ theme }) => theme.background};
`;

export default styled(SearchResult)`
	display: ${(props) => (props.show ? `block` : `none`)};
	${Popover}

	.HitCount {
		display: flex;
		justify-content: flex-end;
	}

	.Hits {
		ol {
			list-style: none;
			margin-left: 0;
		}

		li.ais-Hits-item {
			margin-bottom: 1em;

			a {
				color: ${({ theme }) => theme.foreground};

				h4 {
					margin-top: 0;
					margin-bottom: 0.2em;
				}
			}
		}
	}

	.ais-PoweredBy {
		display: flex;
		justify-content: flex-end;
		font-size: 80%;

		svg {
			width: 130px;
		}
	}
`;
